import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import Stack from '@mui/material/Stack'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import PageHeader from 'src/components/PageHeader/PageHeader'

const ClientBillingConfirmationPage = ({ status }) => {
  let text = ''
  let title = ''
  let icon = null

  if (status === 'cancel') {
    text = 'Your payment has been canceled or aborted.'
    title = 'Payment Canceled'
    icon = <XCircleIcon className={'w-72 h-72 text-red-500'} />
  } else if (status === 'success') {
    text =
      'Your payment has been processed and changes to your account will be made shortly.'
    title = 'Payment Successful'
    icon = <CheckCircleIcon className={'w-64 h-64 text-green-500'} />
  }
  return (
    <>
      <Metadata
        title="Payment Confirmation"
        description="Payment Confirmation page"
      />
      <div className={'flex flex-col h-screen'}>
        <PageHeader title={''} />
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={'h-[calc(100%-12rem)]'}
        >
          {icon}
          <div className="font-bold text-3xl text-gray-800">{title}</div>
          <div className="text-xl text-gray-600">{text}</div>
          <Button
            fullWidth={false}
            startIcon={<ArrowLeftIcon className={'w-4 h-4 stroke-2'} />}
            onClick={() => {
              navigate(routes.settingsClientBilling())
            }}
          >
            Back to Billing
          </Button>
        </Stack>
      </div>
    </>
  )
}

export default ClientBillingConfirmationPage
